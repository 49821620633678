import { FullCycleReadingsResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useFullCycle() {
  const { data: fullCycle, ...rest } = useQuery<FullCycleReadingsResponse>({
    queryKey: ['fullCycleStocks'],
    queryFn: () => {
      console.log('im returning mock data');
      return require('../trend_quality.json');
    },
  });

  return {
    fullCycle,
    ...rest,
  };
}
