import { assetNameMap, formatWeight, ReadingsFilename } from '@3fourteen/core';
import { useRaa } from 'hooks/useRaa';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, CardRowSkeleton, CaretIcon, RebalDate, WeightChangeIcon } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface RaaCardProps {
  cardId: CardProps['cardId'];
}

function RaaCard({ cardId }: RaaCardProps) {
  const { raa } = useRaa(ReadingsFilename.RAA_V2_20);

  return (
    <Card
      cardId={cardId}
      heading='Real Asset Allocation'
      href={routes.models.RAA_V2_20}
      leftEl={!!raa && <RebalDate date={raa.dates[1]} />}>
      <div className='flex flex-col h-full justify-between'>
        {!raa ? (
          <div className='p-4 md:p-6 w-full'>
            <div className='hidden lg:block max-w-xs mx-auto w-full '>
              <Skeleton count={4} style={{ marginTop: 13, marginBottom: 13 }} />{' '}
            </div>
            <div className='lg:hidden'>
              <CardRowSkeleton numberOfItems={4} />
            </div>
          </div>
        ) : (
          <div className='lg:max-w-xs mx-auto w-full  lg:px-6 py-4 lg:pb-0'>
            <p className='text-xs md:text-sm uppercase font-semibold px-4 lg:mt-3 mb-3 lg:mb-2 text-gray-500'>
              Official Weights
            </p>
            <ul className='flex justify-between lg:block'>
              {raa.readings.map(({ name, weight, benchmark_weight }, i) => (
                <li
                  key={`${name}-${i}`}
                  className='lg:py-1.5 flex-1 flex flex-col items-center lg:flex-row justify-between border-r lg:border-r-0 last:border-r-0 lg:border-b border-solid border-zinc-200'>
                  <span className='mb-1 whitespace-nowrap text-xs md:text-sm uppercase lg:capitalize'>
                    {assetNameMap[name] || name}
                  </span>

                  <div className='flex items-center'>
                    <span className='inline-block font-semibold'>{formatWeight(weight[1], 0)}</span>
                    <WeightChangeIcon currentWeight={weight[1]} previousWeight={benchmark_weight} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className='px-4 pb-1.5 pt-1 lg:pt-2 lg:pb-2.5 lg:mt-2 flex lg:justify-between border-t border-solid border-gray-200 lg:border-none'>
          <div className='flex items-center mr-4 lg:mr-0'>
            <CaretIcon direction='up' />
            <span className='text-xs inline-block'>Over benchmark</span>
          </div>
          <div className='flex items-center'>
            <CaretIcon direction='down' />
            <span className='text-xs inline-block'>Under benchmark</span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default memo(RaaCard);
